// eslint-disable-next-line
'use strict';

module.exports = {
	node_env: 'prod',
	proxyPort: 5000,
	publicDomain: 'texasbanditcoonguard.com',
	stripe: {
		secretKey: 'sk_live_K2wss3YMopeShInhHg2Iw9WY',
		publishableKey: 'pk_live_fpcYDUkCa2gNAKqDtQdjS9VX',
		clientId: 'ca_FsntGpJQKPYZcau9r0T9F8SYWL4KCsUg',
		authorizeUri: 'https://connect.stripe.com/express/oauth/authorize',
		tokenUri: 'https://connect.stripe.com/oauth/token'
	}
};
